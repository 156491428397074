import React from 'react';
import {
  DisplayText,
  Header,
  LayoutUI,
  Stack,
  WithFloatingArrow
} from '../../../../ui-kit';

import Layout from '../../../../components/layout';

import * as styles from './ComingSoon.module.scss';

export default function ComingSoon() {
  return (
    <Layout>
      <div className={styles.Container}>
        <LayoutUI narrow>
          <Stack
            alignment="center"
            distribution="center"
            spacing="loose"
            vertical
          >
            <DisplayText>Coming soon!</DisplayText>
            <Header size="small">
              We are updating this program. Stand by for a new and improved
              version of our online course!
            </Header>
            <WithFloatingArrow>
              {floatingArrow => (
                <a href="mailto:hello@projecthappiness.org">
                  Let us know if you want to be the first notified!
                  {floatingArrow}
                </a>
              )}
            </WithFloatingArrow>
          </Stack>
        </LayoutUI>
      </div>
    </Layout>
  );
}
